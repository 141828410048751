
import React from "react"

import PackagescheduleeditorPage from "../../components/negosyonow/controls/packagescheduleeditor";


const onsbookingstatuslist = [
	{"value":1,"display":"Ordered"},
	{"value":4,"display":"Allocated/Scheduled"},
	{"value":9,"display":"For scheduling (courier)"},
	{"value":10,"display":"For coordination (e.g. bus shipping)"},
	{"value":6,"display":"For re-scheduling"},
	{"value":7,"display":"Accepted/For dispatching"},
	{"value":8,"display":"Packed"},
	{"value":2,"display":"Dispatched by Seller"},
	{"value":3,"display":"Completed"},
	{"value":5,"display":"Cancelled"},
	{"value":12,"display":"Reschedule Pick up/Delivery"}
];


const searchFields = [
	{"label":"Order Date", "dbfield": "onsorderform_datetime", "type": "datetime", "filtertype": "datetime"},
	{"label":"Order #", "dbfield": "onsorderform_code", "type": "text", "filtertype": "textbox"},
	{"label":"Seller", "dbfield": "onsbranch_name", "type": "text", "filtertype": "textbox"},
	{"label":"Fulfillment Method", "dbfield": "onsorderformfulfillment_name", "type": "text", "filtertype": "textbox"},
	{"label":"Location/Provider", "dbfield": "nnpickup_name", "type": "text", "filtertype": "textbox"},

	{"label":"Customer", "dbfield": "onscustomer_name", "type": "text", "filtertype": "textbox"},
	{"label":"Order Status", "dbfield": "onsbookingstatus_id", "type": "combo", "filtertype": "combo", "options":onsbookingstatuslist},
	{"label":"Drop off Date", "dbfield": "onsbooking_targetdropoffdate", "type": "datetime", "filtertype": "datetime"},
	{"label":"Drop off Location", "dbfield": "nndropoff_name", "type": "text", "filtertype": "textbox"},
	{"label":"Delivery/Availability Date", "dbfield": "onsbooking_targetdeliverydate", "type": "datetime", "filtertype": "datetime"},
	{"label":"Last Updated", "dbfield": "onsbooking_updated", "type": "datetime", "filtertype": "datetime"},
];

const PackagepickupschedulePage = ({location}) => {


	return <PackagescheduleeditorPage
			location={location}
			encodertype={2}
			searchFields={searchFields}
			userSearchParam={" and 3=ONSBOOKING.onsorderformfulfillment_id and ONSBOOKING.onsbookingstatus_id not in (1,3,5)"}
		/>

}


export default PackagepickupschedulePage;
